import React from 'react';
import { Container } from 'react-bootstrap';
import { Link } from 'react-scroll';
import { graphql, Link as GatsbyLink, useStaticQuery } from 'gatsby';
import GithubButtons from '../GithubButtons/GithubButtons';

import { githubButtons } from '../../mock/data';

const Footer = () => {
  const data = useStaticQuery(graphql`
    query footerData {
      allMockJson {
        nodes {
          footerData {
            networks {
              name
              url
            }
          }
        }
      }
    }
  `);

  const footer = data.allMockJson.nodes[0].footerData;

  const { networks } = footer;
  const { isEnabled } = githubButtons;

  return (
    <footer className="footer navbar-static-bottom">
      <Container>
        <span className="back-to-top">
          <Link to="hero" smooth duration={1000}>
            <i className="fa fa-angle-up fa-2x" aria-hidden="true" />
          </Link>
        </span>
        <div className="social-links">
          {networks &&
            networks.map((network) => {
              const { name, url } = network;
              return (
                <a
                  key={name}
                  href={url || 'https://github.com/cobidev/gatsby-simplefolio'}
                  rel="noopener noreferrer"
                  target="_blank"
                  aria-label={name}
                >
                  <i className={`fa fa-${name || 'refresh'} fa-inverse`} />
                </a>
              );
            })}
        </div>
        <hr />
        <p className="footer__text">
          © {new Date().getFullYear()} - Tobias Meyer - Template developed by{' '}
          <a href="https://github.com/cobidev" target="_blank" rel="noopener noreferrer">
            Jacobo Martínez
          </a>
        </p>

        {isEnabled && <GithubButtons />}
        <p className="footer__text">
          <GatsbyLink to="/imprint">Imprint</GatsbyLink>
        </p>
      </Container>
    </footer>
  );
};

export default Footer;
